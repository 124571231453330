<template>
  <div>
    <v-app-bar
      app
      flat
      :class="{
        'px-12': $vuetify.breakpoint.mdAndUp,
        'px-0': $vuetify.breakpoint.smAndDown
      }"
      class="animation-toolbar"
      style="background-color:#1a3c4f; position:static"
    >

    <div class="text-center" v-if="$vuetify.breakpoint.smAndUp">
      <v-menu offset-y elevation="0" style="margin-top: -60px; !important">
        <template v-slot:activator="{ on }">
          
          <v-app-bar-nav-icon class="white--text ml-6"  v-on="on"></v-app-bar-nav-icon>
        </template>
        <v-list class="driveop-list">
          <v-list-item class="mt-12"></v-list-item>
          
          <v-list-item class="ml-6 mt-6 menu-item"  @click="$goTo('/about')">
            <v-list-item-content>
              <v-list-item-title :class="[ $route.name == 'About' ? 'secondary-color underline' : 'white--text' ]" class="antenna font-18">{{ $ml.get("header_menu_about") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <span v-if="$route.name == 'About'">
              <v-list-item  class="ml-6" @click="$vuetify.goTo('#mission_vision')">
                <v-list-item-content class="py-0">
                        <v-list-item-title class="antenna white--text font-16 ml-4 ">{{ $ml.get('mission_vision') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item  class="ml-6" @click="$vuetify.goTo('#company')">
                <v-list-item-content class="py-0">
                        <v-list-item-title class="antenna white--text font-16 ml-4 ">{{ $ml.get('company') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item  class="ml-6" @click="$vuetify.goTo('#offices')">
                <v-list-item-content class="py-0">
                        <v-list-item-title class="antenna white--text font-16 ml-4 ">{{ $ml.get('offices') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item  class="ml-6" @click="$vuetify.goTo('#team')" duration="300">
                <v-list-item-content class="py-0">
                        <v-list-item-title class="antenna white--text font-16 ml-4 ">{{ $ml.get('team') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </span>


          <v-list-item class="ml-6 mt-6" @click="$goTo('/solutions')">
            <v-list-item-content>
              <v-list-item-title :class="[ $route.name == 'Solutions' ? 'secondary-color underline' : 'white--text' ]" class="antenna font-18">{{ $ml.get("header_menu_business_solutions") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <span v-if="$route.name == 'Solutions'">
              <v-list-item  class="ml-6" @click="$vuetify.goTo('#company')">
                <v-list-item-content>
                        <v-list-item-title class="antenna white--text font-16 ml-4 ">{{ $ml.get('company') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item  class="ml-6" @click="$vuetify.goTo('#services')">
                <v-list-item-content>
                        <v-list-item-title class="antenna white--text font-16 ml-4 ">{{ $ml.get('services') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item  class="ml-6" @click="$vuetify.goTo('#clients')">
                <v-list-item-content>
                        <v-list-item-title class="antenna white--text font-16 ml-4 ">{{ $ml.get('clients') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </span>

          <v-list-item class="ml-6 mt-6 menu-item" @click="$goTo('/process')">
            <v-list-item-content>
              <v-list-item-title :class="[ $route.name == 'Process' ? 'secondary-color underline' : 'white--text' ]" class="antenna font-18">{{ $ml.get("header_menu_process") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="ml-6 mt-6" @click="$goTo('/contactUs')">
            <v-list-item-content>
              <v-list-item-title :class="[ $route.name == 'Join' ? 'secondary-color' : 'white--text' ]" class="antenna font-18">{{ $ml.get("header_menu_contact") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-row justify="center" v-if="$vuetify.breakpoint.xsOnly">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <template v-slot:activator="{ on }">
        <v-app-bar-nav-icon class="white--text ml-6"  v-on="on"></v-app-bar-nav-icon>
      </template>
      <v-card style="primary-background">
        
        
        <v-toolbar-title style="display: flex;" ml-4 my-12>
          <v-layout ml-6 my-8>
            <v-flex style="display: flex; align-items: center;">
              <img class="clickable" @click="$goTo('/')" :src="images.logo2"  width="180px"/>
            </v-flex>
            <v-flex style="display: flex; align-items: center; justify-content: flex-end;">
               <v-btn icon dark @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-flex>
          </v-layout>
            
          
          </v-toolbar-title>

        <v-list class="driveop-list">
          
          <v-list-item class="ml-6 menu-item" @click="$goTo('/about', 'about_us'), dialog = false">
            <v-list-item-content>
              <v-list-item-title :class="[ $route.name == 'About' ? 'secondary-color underline' : 'white--text' ]" class="antenna font-21">{{ $ml.get("header_menu_about") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="ml-6 mt-6" @click="$goTo('/solutions', 'business_solutions'), dialog = false">
            <v-list-item-content>
              <v-list-item-title :class="[ $route.name == 'Solutions' ? 'secondary-color underline' : 'white--text' ]" class="antenna font-21">{{ $ml.get("header_menu_business_solutions") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="ml-6 mt-6" @click="$goTo('/process', 'process'), dialog = false">
            <v-list-item-content>
              <v-list-item-title :class="[ $route.name == 'Join' ? 'secondary-color' : 'white--text' ]" class="antenna font-21">{{ $ml.get("header_menu_process") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="ml-6 mt-6" @click="$goTo('/portfolio', 'portfolio'), dialog = false">
            <v-list-item-content>
              <v-list-item-title :class="[ $route.name == 'Join' ? 'secondary-color' : 'white--text' ]" class="antenna font-21">{{ $ml.get("header_menu_portfolio") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="ml-6 mt-6" @click="$goTo('/contactUs', 'contact'), dialog = false">
            <v-list-item-content>
              <v-list-item-title :class="[ $route.name == 'Join' ? 'secondary-color' : 'white--text' ]" class="antenna font-21">{{ $ml.get("header_menu_contact") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
        
      </v-card>
    </v-dialog>
  </v-row>


    <v-spacer></v-spacer>
    <img v-if="$vuetify.breakpoint.smAndUp && $route.name != 'Landing'" class="clickable" @click="$goTo('/')" :src="images.logo"  width="220px"/>
    <img v-if="$vuetify.breakpoint.xsOnly" class="clickable mr-6" @click="$goTo('/')" :src="images.logo2"  width="200px"/>

 <v-spacer class="hidden-xs-only"></v-spacer>

          <!-- <v-btn
            text
            class="lowercase hidden-xs-only"
            color="primary"
            :ripple="false"
          ><i class="material-icons white--text">search</i></v-btn> -->

    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      images: {
        logo: require("../assets/img/brand/driveop1.png"),
        logo2: require('../assets/img/brand/driveop2.png'),
        logo_black: require("../assets/img/brand/logo-white.png"),
        divider_line: require("../assets/img/images/divider-line.png")
      }
    };
  },
  computed: {
    menu() {
      return [
        {
          title: this.$ml.get("header_menu_about"),
          show: true,
          class: 'antenna white--text font-21',
          action: () => this.changeRoute("/about")
        },
        {
          title: this.$ml.get("header_menu_business_solutions"),
          show: true,
          class: 'antenna white--text font-21',
          action: () => this.changeRoute("/solutions")
        },
        {
          title: this.$ml.get("header_menu_join"),
          show: true,
          class: 'antenna white--text font-21',
          action: () => this.changeRoute("/join")
        },
        {
          title: this.$ml.get("header_menu_login"),
          show: true,
          class: 'antenna white--text font-21',
          action: () => this.changeRoute("/about")
        },
      ];
    }
  },
  methods: {
    changeRoute(route, event) {
      if (event) {
        this.$analytics(event);
      }
      this.dialog = false;
      this.$router.push(route);
    }
  }
};
</script>

<style scoped>

.driveop {
  color: #fff;
  font-family: 'Venera-Bold';
  letter-spacing: 8px;
  font-size: 20pt;
}
.driveop span {
  font-family: 'Venera';
}

.v-menu__content {
  margin-top:-60px;
  left: 25px;
}

.v-list.v-sheet {
  height: auto;
}

.v-list-item__title:hover {
  text-decoration: underline;
  color: #0bd1cb !important;
  cursor: pointer;
}

.theme--light.v-card {
  background-color: #029599;
}

.theme--light.v-list {
  background-color: #029599;
}

</style>


